li.cloudmenu {
    position: relative;

}

svg.cloudmenu.cloud {
    position: absolute;
	/* top: 0;
	left: 0;
	right: 0;
	bottom: 0; */
}

.cloudmenu {
	pointer-events: none;
}
  
.cloudmenu circle {
	pointer-events: all;
}

.cloudChildren {
	color: var(--main-cta);
	cursor: pointer
}

img.mehecske {
    position: absolute;
    right: 0px;
    top: -10px;
	pointer-events: painted;
	animation: randomMovement 3s ease-in-out infinite;
}

@media (min-width: 768px) {
	.cloudChildren {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 28pt;
	}
}

@media (max-width: 767px) {
	.cloudmenu {
		pointer-events: all;
	}

	.nav-item.cloudmenu {
		width: initial!important;
		height: initial!important;
		text-align: center;
		margin: 10px 0;
	}

	svg.cloudmenu.cloud {
		display: none;
	}

	img.mehecske {
		width: 50px;
		right: 80px;
		top: -26px;
	}
}

@keyframes randomMovement {
	0% {
	  transform: translate(0, 0);
	}
	25% {
	  transform: translate(5px, 10px);
	}
	50% {
	  transform: translate(5px, 0px);
	}
	75% {
	  transform: translate(0px, 10px);
	}
	
}
