div#root {
  padding: 30px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h1 {
  color: var(--main-h1);
  font-weight: 400;
}

.content h1 {
  margin-left: 15px;
}

h2 {
  color: var(--main-h2);
  font-weight: 400;
}

p {
  margin: 0px 0 15px;
  line-height: 1.7;
}

div#comingsoon img {
  max-width: 100%;
}

ul.nav {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

@media (min-width: 768px) { /* TBD */
  ul.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  
  ul.nav li:nth-child(1) {
    transform:translateY(-80px)
  }
  
  ul.nav li:nth-child(2) {
    transform:translateY(40px)
  }
}


/* .content-wrapper {
  border: 30px solid transparent;
  border-image: url('./assets/images/border.png') 30 round;
  max-width: 980px;
  margin: 0 auto;
}

.content {
  background-image: url('./assets/images/paper-texture.png');
  outline: 1px solid red;
  
  margin: -10px;
  padding: 30px;
  height: 500px;
} */

.content-wrapper {
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  background-image: url('./assets/images/paper-texture.png');
  margin: 0 auto;
  padding: 30px;
  min-height: 110px;
}

.content-wrapper:after {
  position: absolute;
  content: '';
  display: block;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: -15px;
  left: -15px;
  border: 30px solid transparent;
  border-image: url('./assets/images/border.png') 30 round;
}

img.csiga {
  position: absolute;
  top: -87px;
  z-index: 1;
}

img.henrik {
  position: absolute;
  width: 165px;
  bottom: -2px;
  right: -10px;
  z-index: 1;
}

img.madar {
  position: absolute;
  left: -257px;
  top: -130px;
}

img.pumpkin {
  position: absolute;
  bottom: 30px;
  left: 40px;
}

img.mogyoro {
  position: absolute;
  right: 0px;
  bottom: 70px;
}

.content {
  z-index: 1;
  position: relative;
  padding-bottom: 50px;
}

ul.bookList {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.bookList li {
  display: inline-block;
}

ul.bookList li {
  display: inline-block;
  margin: 0 15px;
  text-align: center;
  max-width: 200px;
  vertical-align: top;
}



.specificBook {
  overflow: hidden;
}

.floatingCover {
  float: right;
  border-radius: 7px;
  margin-left: 25px;
  margin-bottom: 10px;
}

.specificBook h1 {
  text-align: center;
}

.specificBook a.backLink {
  float: left;
}

.synopsis, .peekInside {
  color: var(--text);
  clear: left;
  padding: 30px;
  padding-left: 0;
  text-align: justify;
  font-size: 19px;
  letter-spacing: 1px;
}

.peekInside {
  padding-top: 0;
}

.synopsis img, .peekInside img {
  margin: 50px auto;
  display: block;
  border-radius: 5px;
}

.peekInside img.bookImageFloatLeft {
  float: left;
  max-height: 270px;
  margin: 0px 20px 0px 0px;
}

.peekInside img.bookImageFloatRight {
  float: right;
  max-height: 270px;
  margin: 0px 0px 0px 20px;
}

ul.characterList {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.characterList li {
  clear: both;
  margin-bottom: 30px;
  overflow: hidden;
}

ul.characterList li img {
    display: inline-block;
    float: left;
    max-width: 115px;
    margin-right: 25px;
}

ul.characterList li:nth-child(even) img {
  float: right;
  margin-left: 25px;
  margin-right: 0;
}

ul.characterList li:after {
  content: "";
  background-image: url('./assets/images/separator.png');
  display: block;
  clear: both;
  height: 80px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  margin-top: 80px;
}

ul.characterList li:nth-child(even):after {
  transform: scaleX(-1);
}

@media (max-width: 1850px) {
  img.mogyoro {
    display: none;
  }
}

@media (max-width: 1610px) {
  .nav-wrapper {
    width: 70%; /* Adjust the container width */
    margin: 0 auto; /* Center the container */
  }

  ul.nav {
    transform: scale(0.7);
    gap: 0px;
  }

  ul.nav li {
    /* transform: scale(0.7); */
  }

  ul.nav li:nth-child(1) {
    transform: /* scale(0.7)  */translateY(-80px);
  }

  ul.nav li:nth-child(2) {
    transform: /* scale(0.7)  */translateY(40px);
}
  
  img.sun {
    width: 200px;
  }

  header {
    justify-content: end;
  };
}

@media (max-width: 1579px) {
  img.madar {
    width: 130px;
    left: -128px;
    top: -76px;
  }

  img.csiga {
    width: 90px;
    top: -59px;
  }
}

@media (max-width: 1319px) {
  img.madar {
    display: none;
  }

  ul.nav {
    transform-origin: right;
  }
}

@media (max-width: 1139px) {
  .nav-wrapper {
    width: 65%; /* Adjust the container width */

  }
  ul.nav {
    transform: scale(0.65);
  }

}

.navMenuToggle {
  display: none;
}

@media (max-width: 979px) { /* TBD */
  
  #root {
    width: 100%;
    padding: 0!important;
  }
  
  header {
    /* background-color: white;
    margin: 160px 0 100px; */
    padding-top: 160px;
    margin-bottom: 40px;
  }

  .navMenuToggle {
    display: block;
    position: absolute;
    background: url(./assets/images/responsiveCloudToggle.svg);
    background-repeat: no-repeat;
    right: 10px;
    top: 10px;
    background-position: -3px 4px;
    background-size: 100%;
    font-size: 26px;
    padding: 23px 31px 20px;
    color: var(--main-cta);
    cursor: pointer;
    user-select: none;
  }

  img.sun {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: -1;
  }

  img.csiga {
    width: 90px;
    top: -59px;
    right: 20px;
  }

  .menuWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 50px;
    top: -50px;
  }

  .menuWrapper {
    width: 100%;
  }

  ul.nav {
    background-color: white;
    position: relative;
    transform: initial;
    height: 138px;
    transition: all 0.3s;
    margin-bottom: 60px;
  }

  ul.nav.responsiveHidden {
    height: initial;
    height: 0;
    opacity: 0;
    margin-bottom: 0;
  }

  ul.nav:before, ul.nav:after  {
    content: "";
    background: url(./assets/images/longcloud.svg);
    width: 100%;
    height: 50px;
    display: block;
    position: absolute;
    
  }

  ul.nav:before {
    top: -50px;
    background-position: -70px -5px;
  }

  ul.nav:after {
    bottom: -50px;
    background-position: -130px -100px;
  }

  ul.nav li {
    transform: initial!important;
  }

  .content-wrapper {
    width: 70%;
  }

  ul.bookList {
    text-align: center;
  }

  ul.bookList li {
    margin-bottom: 40px;
  }

  .specificBook {
    display: flex;
    flex-direction: column;
  }

  .synopsis, .peekInside {
      font-size: 17px;
      padding-right: 0;
      order: 4;
  }

  img.floatingCover {
    margin: 0 auto;
    order: 2;
    max-width: 177px;
  }

  .specificBook a.backLink {
      order: 1;
      /* text-align: center; */
      margin-bottom: 30px;
  }

  .specificBook h1 {
      order: 3;
      font-size: 27px;
      margin-top: 25px;
      margin-bottom: 0;
  }

  /* .specificBook button {
      order: 5;
  } */

  .specificBook > a {
    order: 5;
  }

  .synopsis img, .peekInside img {
    max-width: 100%;
  }

  .specificBook a.backLink img {
    width: 50%;
    max-width: 178px;
  }

  .synopsis img, .peekInside img {
    max-width: 100%;
  }

  .content-wrapper {
      margin-bottom: 160px;
  }

  img.mogyoro {
      bottom: -140px;
      width: 180px;
      left: 0;
      transform: scaleX(-1) rotate(-8deg);

  }

  img.henrik {
      width: 100px;
  }
  
  img.pumpkin {
    width: 100px;
    bottom: -109px;
    right: 20px;
    left: initial;
  }
}

@media ( max-width: 767px ) {
  .bookImageFloatRight, .bookImageFloatLeft {
    float: none!important;
    margin: 0 auto!important;
  }

  ul.characterList li {
    text-align: center;
  }

  ul.characterList li img {
    float: none!important;
  }

  ul.characterList li:after {
    background-size: 80%;
  }
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 */