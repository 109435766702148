:root {
    --main-cta: #f59000;
    --main-h1: #007012;
    --main-h2: #f59000;

    --text: #333333;
}

body {
	margin: 0;
	min-height: 100vh;

	background: url('./assets/images/bck-grass.png');
    background-repeat: repeat;

	font-family: "baileywick-jf-gothic", sans-serif;
	font-weight: 400;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body:before {
    content: "";
    height: 400px;
    background: url('./assets/images/bck-sky.png');
    display: block;
    position: absolute;
	z-index: -1;
    width: 100%;
    background-repeat: repeat;
	top: 0;
}

body:after {
    content: "";
    height: 130px;
    background: url(./assets/images/bck-hedge.png);
    display: block;
    position: absolute;
	z-index: -1;
    width: 100%;
    background-repeat: repeat-x;
    top: 340px;
}

a {
    color: var(--main-cta);
}

.App {
    position: relative;
}

img {
    height: auto;
}
